import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class WebtasarimPage extends React.Component {
  render() {

    const data = this.props.data

        //control
        const pic05 = data.pic05.childImageSharp.fluid
        const pic06 = data.pic06.childImageSharp.fluid
        const pagebgwebtasarim = data.pagebgwebtasarim.childImageSharp.fluid


return (
  <Layout pageTitle="Web Tasarım - Web Yazılım">
  <SEO title="Web Tasarım - Web Yazılım" keywords={[`istanbul Web Tasarım`, `Web Tasarım`, `E-Ticaret`]} description="Kurumsal Web Tasarım,Bulut tabanlı yazılım ihtiyaçlarınız için İstanbul Web Tasarım" />

    <section className="bginpage">
    <Img fluid={pagebgwebtasarim}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Web Tasarım"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Web Tasarım ve Yazılım
             </h1>
             <p>Burada Web Tasarım konuşulur, Firmanız için Web yazılımları yapılır, Kurumsal Kimliğiniz desteklenir, Firmanızı en iyi şekilde tanıtmak için fikirler üretilir.</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md={{ size: 10, offset: 1 }}  className="text-center pdbot30">
      <h1 className="font-size-normal  section-title">
               <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
               İstanbul Web Tasarım
                <div className="bar"></div>
           </h1>
           <p>Burada Web Tasarım konuşulur, Firmanız için Web yazılımları yapılır, Kurumsal Kimliğiniz desteklenir, Firmanızı en iyi şekilde tanıtmak için fikirler üretilir. Bizim işimiz bizi tercih edenleri potansiyel müşterilerine en iyi şekilde ulaştırmak ve tanıtmaktır,web tasarım, e-ticaret çözümü ,arama motoru optimizasyonu, kurumsal kimlik çalışması yaptırabileceğiniz işinize saygı gösteren ve işinize sizin titizliğinizde yaklaşan bir yer arıyorsanız, bütün birikimimizle, size yol göstermek ve sizinle birlikte ilerlemek için hazır ve istekliyiz. Siz de hazırsanız buyrun, başlayalım!</p>
           </Col>
           </Row>

           <Row>
            <Col md="4">
             <Img fluid={pic05} alt="Mobil Web Tasarım" className="mt-50" />

            </Col>
            <Col md="8">
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>

                <h3 className="font-size-normal"> <small className="color-primary">Web Tasarım &amp; Web Yazılım</small> Web Tasarım ve Programlama</h3>
                <p>Müşterilerimizin istek ve hedeflerini iyi anlayıp, onları iş ortağı gören bir anlayış ile web ortamındaki ihtiyaçlarını karşılamak için tüm enerji ve birikimimizi web tasarım ve programlama için kullanıyoruz.</p>
              </div>
              </Col>
              </Row>
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>
<h3 className="font-size-normal"> <small className="color-primary">Kusursuz Uyumluluk</small> Responsive Tasarım</h3>
                <p>Yaptığımız web siteleri tüm mobil cihazlara (iPhone, iPad, Samsung v.b) ve tüm tarayıcılarda (Chrome, İExplorer, Firefox, v.b) kusursuz bir esneklik ile çalışmaktadır.</p>
              </div>
              </Col>
              </Row>
              <Row>
              <Col md="12" lg="12">
              <div className="single-features">
                <div className="icon">
                <i className="icon-3x fa fa-desktop"></i>
                </div>
                <h3 className="font-size-normal"> <small className="color-success">Arama Motoru Optimizasyonu</small> Google Seo,Yandex Seo,Bing Seo ...</h3>
                <p>Sizler için Oluşturduğumuz Web sitesi Google, Yandex, Bing, Yahoo ve diğer arama motorlarında ekstra herhangi bir çalışma yapmadan üst sıralarda yer alacak şekilde kodlanarak optimize edilmektedir.</p>
              </div>
              </Col>
              </Row>
            </Col>
           </Row>


           <Row>
            <Col md="8">
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> <small className="color-success">Navigasyon,Animasyon,İçerik vb..</small> Bütünlük</h3>
              <p>Oluşturduğumuz web sitelerinin sizlerin kurumsal kimliğini potansiyel müşterilerinize en doğru şekilde yansıtması gerektiğine sahip bir anlayış ile site içi bütünlük ile markalaşmanıza yardımcı oluyoruz.</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> <small className="color-success">Müşterinin ve sektörünün kapsamlı analizi</small> Rekabet Analizi ve Proje</h3>
              <p>Markanız için geliştireceğimiz Dijital Pazarlama stratejilerinde Hedef Kitlenizi ve hedef kitlenizin isteklerini en iyi şekilde belirlerleyerek Sektörel Rekabet Analizleri (SWOT vb…) yaparak en uygun çalışmayı sizlere sunuyoruz….</p>
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="12" lg="12">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> <small className="color-success">Başarıya Odaklanılmış Takım çalışması</small> Kaçınılmaz Sonuçlar</h3>
              <p>Başarıya odaklı çalışma modellerimiz ile bir arkadaşımız sizin viral kampanyalarınızı en doğru şekilde oluşturmaya yardım ederken bir arkadaşımızda web sitenizin arama motorlarında nasıl daha popüler olacağını inceliyor olacaktır ki 7 kişiden oluşan kadromuza birde siz eklendiğinizde ortaya mükemmel bir çalışma çıkacaktır.</p>
            </div>
            </Col>
            </Row>
            </Col>
            <Col md="4">
             <Img fluid={pic06} alt="Mobil Web Tasarım" className="mt-50" />
            </Col>
           </Row>

           <Row>
            <Col md="6">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal">Kaçınılmaz Sonuçlar</h3>
              <p>Sizin için oluşturduğumuz çalışma planı sonucunda potansiyel müşterilerinize ulaşabiliyor ve onlara kurumsal kimliğinizi , ürünlerinizi ve hizmetlerinizi en doğru şekilde iletebilirsiniz.</p>
            </div>
            </Col>
            <Col md="6">
            <div className="single-features">
              <div className="icon">
              <i className="icon-3x fa fa-desktop"></i>
              </div>
              <h3 className="font-size-normal"> Yedekleme ve Güncelleme</h3>
              <p>Sizler için ve sizlerinde yardımıyla sürekli olarak çalışarak oluşturduğumuz web sitelerinizi en güncel halleri ile belirli periyotlarla yedeklerini alıyor ve gerekli güncellemeleri yapıyoruz.</p>
            </div>
            </Col>
           </Row>
           <Row>

           </Row>


    </Container>
    </section>

    <section className="section minemacera bg-f5 br-top-de pdtop30 pdbot30">
      <Container>
        <Row>
          <Col md="12" className="text-center">
            <h3>Mine Hanım’ın Web Tasarım Hikayesi</h3>
            <p>Mine Hanım 28 li yaşlarda genç bir girişimci ,internetteki potansiyel müşterilerine ulaşmayı ve iş hacmini genişletmek isteyen bir girişimci hemde.Peki Mine Hanımın Web Tasarım konusundaki hikayesi nedir ? Buyrun İzleyelim</p>
            <iframe src="//player.vimeo.com/video/94740358" title="web-tasarim" width="100%" height="320" frameBorder="0"></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pic05: file(
 relativePath: { regex: "/mobile-view-map1.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pic06: file(
 relativePath: { regex: "/mobile-two.png/" }
) {
childImageSharp {
           fluid{
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
pagebgwebtasarim: file(
 relativePath: { regex: "/bg11.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
